.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* The Modal (background) */
.modal-body {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.w100 {
    width: 100%;
}

.list-action-col {
    display: flex;
    justify-content: space-evenly;
}

.list-toolbar {
    padding-right: 10px;
}

[class^="SimpleFormIterator-form-"] {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
}


.AutoCompletePopList{
    position: absolute !important;
    margin-left: 150px !important;
    min-width: 256px !important;
}

.RuleOperatorsItem {
    flex-grow: 3;
}

.reward-container {
    display: flex;
    justify-content: space-evenly;
}
.reward-align{
    float: left;
    margin-left: 10px;
}

.loading-tag {
    position: relative;
    left: 150px;
    top: -60px;
}
.aside-simple-text-layout{
    margin-bottom:7px;
    min-width: 226px;
    float:left;
}
.card-slider-container{
    max-width:150px;
    margin-top: -28px;
    margin-bottom: -5px;
    margin-left: 20px;
}
.card-slider-title{
    position: relative;
    left: 37px;
    top: 53px;
    color: gray;
}
.cards-grid-container{
    display: flex;
    flex-wrap: wrap;
}

.forked-from-text-layout{
    margin-top:-75px !important;
    left:280px;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #283593; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin-right: 10%;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.creative-subheader {
    display: flex;
    flex-direction: column;
}

.card-image-holder img{
    min-height: 220px;
    min-width: 300px;
    max-height: fit-content !important;
}

.list-aside-img {
    max-height: 50%;
    max-width: 100%;
    object-fit: scale-down;
    margin-top: 20px;
    margin-bottom: 5px;

}

.hide-filter {
    top: -30px !important;
    left: -27px !important;
}

.read-only-text-Field {
    margin-top: 10px !important;
    width: -webkit-fill-available;
}

.variation-card-filetypes {
    position: relative;
    top: 40px;
    background-color: white;
    opacity: 0.7;
    display: inline-block;
}

.filter-text-style {
    max-width: 160px;
}

.cursor-pointer {
    cursor: pointer;
}

.asideCreativeName {
    width: 315px;
    font-size: 14px;
    overflow-wrap: normal;
    color: #767474;
    font-weight: normal;
}

.selectedCreative {
    color: green;
    font-weight: bold !important;
    font-size:  16px !important;
}

.creative {
    display: flex;
    flex-direction: column;
}

.creative-tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.edit-tags {
    width: 75% !important;
    justify-content: space-between;
}

.tag-form-toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.creative-expand-img {
    max-height: 300px;
    max-width: 100%;
    object-fit: scale-down;
    margin-top: 5px;
    margin-bottom: 5px;
}

.creative-card-img {
    max-height: 90%;
    max-width: 100%;
    object-fit: scale-down;
    margin-top: 5px;
    margin-bottom: 5px;
}

.failed-upload-list-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%
}

.variation-list-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.variation-list-form > div {
    padding: 3px;
    flex-grow:0;
}

.filter-form-buttons {
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}

.filter-buttons-component {
    display: block;
    width: 100%;
}

.filter-form-button:not([disabled]) {
    color: #3f51b5 !important;
}

.apply-filter-btn {
    margin-left: 20px;
    margin-right: 10px;
}

.pos-relative {
    position: relative;
}

.remove-filter-icon {
    position: absolute;
    left: -5px;
    top: -5px;
}

.remove-filter-icon:hover {
    background-color: #d3d3d3;
    cursor: pointer;
}

.not-filtered-fields {
    width: 100%;
    display: flex;
    align-content: space-between;
}

.not-filtered-fields > div {
    padding: 3px
}

.subs-list-title {
    margin: 10px;
    margin-top: 25px;
    display: flex;
}

.subs-list-header {
    line-height: 25px;
    font-weight: bold;
}

.drawer-action-btns {
    display: flex;
    justify-content: space-evenly;
}

.drawer-form {
    width: 300px;
}

.filter-drawer-container{
    width:400px;
    margin: 20px;
}

.create-filter-container{
    display:grid;
    margin: 20px 0px;
}

.creative-select-cb {
    position: absolute !important;
    left: 10px;
}

.creative-card-filename {
    font-size: 13px;
    opacity: 0.7;
}

.multiple-upload-options {
    display: flex;
    flex-direction: column;
}

.fade-enter {
    opacity: 1 !important;
    transform: none !important;
}